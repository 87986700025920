//
//
// forms.scss
//
//

.form-control{
  transition: background-color $transition-time ease, border-color $transition-time ease, opacity $transition-time ease;
  &:disabled{
    opacity: .5;
  }
  &[readonly]{
    background: $input-bg;
  }
}

.form-group{
  label{
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    color: $text-muted;
    &.custom-control-label{
      color: $body-color;
      &:before, &:after{
        top: 0.125rem;
      }
    }
  }
  &.rounded{
    border-radius: $border-radius !important;
  }
}

.text-light{
  .form-group{
    > label{
      color: rgba($white,.75);
    }
  }
}

@include media-breakpoint-down(sm){
  .form-control{
    font-size: 16px;
  }
}

// Input Groups

.input-group{
  .icon{
    *{
      fill: $headings-color !important;
    }
  }
  .input-group-text{
    font-weight: $font-weight-bold;
  }
}


// Custom Selects

.form-group{
  position: relative;
  .custom-select{
    cursor: pointer;
    background: none;
    + .icon{
      pointer-events: none;
      position: absolute;
      top: $spacer/3;
      right: $spacer/2;
      transform: rotateZ(-90deg);
      path{
        fill: $gray-900;
      }
    }
    &.custom-select-lg{
      + .icon{
        top: $spacer/1.25;
      }
    }
  }
}

// Custom Controls

.custom-control{
  label{
    cursor: pointer;
  }
}

// Custom Radio

.custom-radio{
  line-height: 1.3125rem;
  min-height: 1.3125rem;
}

// Custom Checkboxes

.custom-control{}

.custom-control-label{
  user-select: none;
  &:before{
    border: $border-width solid $border-color;
  }
  &.text-small{
    &:before,&:after{
      top: 0.125rem;
    }
  }
}

.custom-control-input{
  &:checked{
    ~ .custom-control-label{
      &:before{
        border-color: $component-active-bg;
      }
    }
  }
}

// Custom Checkbox Switches

.custom-control{
  padding-left: $spacer*1.25;
  .custom-control-label{
    &:before, &:after{
      left: -$spacer*1.25;
    }
  }
}

.custom-switch{
  padding-left: $spacer*2.5;
  .custom-control-label{
    &:before{
      height: $spacer;
      left: -($spacer*2.5);
      top: 0;
    }
    &:after{
      top: $spacer/6;
      left: -($spacer*2.25);
      background-color: white;
      border: 1px solid $border-color;
    }
  }
  .custom-control-input{
    &:checked{
      ~ .custom-control-label{
        &:after{
          transform: translateX(1.25rem);
          border-color: white;
        }
      }
    }
  }
}

// Custom Range

.custom-range-labels{
  margin-top: .25rem;
  display: flex;
  justify-content: space-between;
  > span{
    font-size: $small-font-size;
    min-width: 13px;
    text-align: center;
    color: $text-muted;
    font-weight: $font-weight-bold;
    display: block;
    &:before{
      content: '';
      height: .125rem;
      width: 2px;
      height: .4rem;
      background: $text-muted;
      display: block;
      margin: 0 auto;
      margin-bottom: .5rem;
      opacity: .5;
    }
    &:first-child, &:last-child{
      &:before{
        height: .6rem;
      }
    }
  }
}

// reCAPTCHA

[data-recaptcha]{
  &:not([data-size="invisible"]){
    margin-bottom: $spacer/2;
  }
}

.grecaptcha-badge{
  display: none;
}
